"use strict";

import axios from "axios";
import store from "@/store";

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  timeout: 100000,
});

const requestHandler = (request) => {
  store.commit("axiosRequest");
  return request;
};

const errorHandler = async (error) => {
  console.log("Error Interceptor", error);
  store.commit("axiosError");
  if (error.response) {
    if (error.response.status === 401) {
      // if ( await refreshTokenInternal()) {
      //   return  Promise.resolve(_axios(error.config))
      // } else {
      //   window.location.replace('/')
      //   return Promise.reject(new Error('로그인이 만료 되었습니다.'))
      // }
    }
  }
  console.log("error reject");
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  store.commit("axiosSuccess");
  return response;
};

_axios.interceptors.request.use((request) => requestHandler(request));

_axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default _axios;
