import i18n from "@/i18n";

const routes = {
  path: "/findlocation",
  name: "findlocation",
  component: () =>
    import(
      /* webpackChunkName: "findlocation" */ "@/views/findlocation/FindlocationMain"
    ),
  children: [
    {
      path: "stores",
      alias: "",
      name: "Stores",
      component: () =>
        import(
          /* webpackChunkName: "findlocation" */ "@/views/findlocation/Stores"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.find_location.find_location"),
        m_subtitle: i18n.t("menu.find_location.find_location"),
        menu_num: 5,
        sub_menu_num: 51,
        step: [i18n.t("menu.find_location.find_location")],
        location: "/findlocation/stores",
      },
    },
    {
      path: "StoresView/:id",
      name: "StoresView",
      component: () =>
        import(
          /* webpackChunkName: "findlocation" */ "@/views/findlocation/StoresView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.find_location.find_location"),
        m_subtitle: i18n.t("menu.find_location.find_location"),
        menu_num: 5,
        sub_menu_num: 51,
        step: [
          i18n.t("menu.find_location.find_location"),
          /* i18n.t("page.find_location.location_information"), */
        ],
        location: "/findlocation/stores",
      },
    },
  ],
};

export default routes;
